import "./index.css";
import { useEffect } from "react";

function Profile() {
  return (
    <div id="main" style={{ height: window.innerHeight + "px" }}>
      <div id="background-block">
        <img
          id="avatar"
          src="https://avatars.githubusercontent.com/u/119416575?v=4"
          alt="The avatar of Linxin"
        />
        <h2 id="name">Linxin</h2>
        <p id="intro">A software developer from China and have some practices on web applications.</p>
        <div id="dividing-line">
          --------------&nbsp;&nbsp;&nbsp;&nbsp;
          Links&nbsp;&nbsp;&nbsp;&nbsp;--------------
        </div>
        <ul id="social-links">
          <li className="social-link">
            <a href="https://twitter.com/Linxin4cs" target="_blank">
              <img
                src="https://raw.githubusercontent.com/rahuldkjain/github-profile-readme-generator/master/src/images/icons/Social/twitter.svg"
                className="social-link-icon"
                alt="The icon of Twitter"
              ></img>
            </a>
          </li>
          <li className="social-link">
            <a href="https://github.com/linxin4cs" target="_blank">
              <img
                src="https://raw.githubusercontent.com/rahuldkjain/github-profile-readme-generator/master/src/images/icons/Social/github.svg"
                className="social-link-icon"
                alt="The icon of Github"
              ></img>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Profile;
