import Profile from "./page/Profile";

function App() {
    return (
        <div className="App">
            <Profile/>
        </div>
    );
}

export default App;
